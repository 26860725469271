import { API_URL } from "@app/constants";
import type {
	TPingBodySchema,
	TPingResponseSchema,
} from "@pantry-family/schema/ping";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const pingApi = createApi({
	reducerPath: "pingApi",
	baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
	endpoints: (builder) => ({
		ping: builder.query<TPingResponseSchema, TPingBodySchema>({
			query: (body) => ({
				url: "/ping",
				body,
				method: body ? "POST" : "GET",
				headers: { "Content-Type": "application/json" },
			}),
		}),
	}),
});

export const { usePingQuery, useLazyPingQuery } = pingApi;
