import {
	type PayloadAction,
	createSelector,
	createSlice,
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import type { RootState } from "../store";

type AuthState = {
	userId?: string;
};

export const persistConfig = {
	key: "auth",
	storage: storage,
	whitelist: ["userId"],
};

// INITIAL STATE
const initialState = {} satisfies AuthState as AuthState;

// SLICE
export const slice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setUserId: (state, action: PayloadAction<string>) => {
			state.userId = action.payload;
		},
		signOut: () => {
			return initialState;
		},
	},
});

// REDUCER
export const reducer = slice.reducer;

// ACTIONS
export const { setUserId, signOut } = slice.actions;

// SELECTORS
export const selectAuth = (state: RootState) => state.auth;

export const selectUserId = createSelector(selectAuth, (state) => state.userId);
