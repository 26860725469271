import { object, string, type infer as zInfer } from "zod";

export const SignInBodyEmailSchema = string().email();

export const SignInBodySchema = object({
	email: SignInBodyEmailSchema,
}).strict();

export const SignInParamSchema = object({}).strict();

export const SignInQuerySchema = object({}).strict();

export const SignInResponseSchema = object({
	message: string(),
}).strict();

export type TSignInBodySchema = zInfer<typeof SignInBodySchema>;
export type TSignInParamSchema = zInfer<typeof SignInParamSchema>;
export type TSignInQuerySchema = zInfer<typeof SignInQuerySchema>;
export type TSignInResponseSchema = zInfer<typeof SignInResponseSchema>;
