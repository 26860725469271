import {
	type PayloadAction,
	createSelector,
	createSlice,
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import type { RootState } from "../store";

type CounterState = {
	value: number;
};

export const persistConfig = {
	key: "counter",
	storage: storage,
	whitelist: ["value"],
};

// INITIAL STATE
const initialState = {
	value: 0,
} satisfies CounterState as CounterState;

// SLICE
export const slice = createSlice({
	name: "counter",
	initialState,
	reducers: {
		increment: (state) => {
			state.value += 1;
		},
		decrement: (state) => {
			state.value -= 1;
		},
		reset: (state) => {
			state.value = 0;
		},
		incrementByAmount: (state, action: PayloadAction<number>) => {
			state.value += action.payload;
		},
	},
});

// REDUCER
export const reducer = slice.reducer;

// ACTIONS
export const { increment, decrement, incrementByAmount, reset } = slice.actions;

// SELECTORS
export const selectCounter = (state: RootState) => state.counter;

export const selectCounterValue = createSelector(
	selectCounter,
	(state) => state.value,
);
