import { Logo } from "@app/components/logo.component";
import { useDispatch } from "@app/hooks";
import { setUserId } from "@app/store/features/auth";
import {
	Button,
	Container,
	Flex,
	Group,
	Image,
	Paper,
	TextInput,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import {
	SignInBodySchema,
	type TSignInBodySchema,
} from "@pantry-family/schema/sign-in";
import classes from "./sign-in.module.css";

const schema = SignInBodySchema;

export const SignIn = () => {
	const dispatch = useDispatch();
	const form = useForm({
		initialValues: {
			email: "",
		},
		validateInputOnChange: true,
		validate: zodResolver(schema),
	});

	const handleSubmit = async (values: TSignInBodySchema) => {
		if (values.email === "domhadfield@gmail.com") {
			dispatch(setUserId("123456"));
		}
	};

	return (
		<Container className={classes.container}>
			<Paper p="xl" withBorder className={classes.wrapper}>
				<Flex justify="center">
					<Image w={150} h={150} mb="xl" fit="cover" src={Logo} />
				</Flex>
				<form onSubmit={form.onSubmit(handleSubmit)}>
					<TextInput
						required
						label="Email"
						placeholder="email@address.com"
						key={form.key("email")}
						{...form.getInputProps("email")}
					/>
					<Group justify="flex-end" mt="md">
						<Button type="submit" disabled={!form.isValid()}>
							Sign in
						</Button>
					</Group>
				</form>
			</Paper>
		</Container>
	);
};
