export const API_URL = import.meta.env.VITE_API_URL;
export const APP_NAME = import.meta.env.VITE_APP_NAME;
export const CLIENT_EVENTS_AUTHORISER = import.meta.env
	.VITE_CLIENT_EVENTS_AUTHORISER;
export const CLIENT_EVENTS_ENDPOINT = import.meta.env
	.VITE_CLIENT_EVENTS_ENDPOINT;
export const FLAGSMITH_CLIENT_KEY = import.meta.env.VITE_FLAGSMITH_CLIENT_KEY;
export const IS_DEV = import.meta.env.DEV;
export const STAGE = import.meta.env.VITE_APP_STAGE;
export const VERSION = import.meta.env.VITE_APP_VERSION;
