import { object, string, type infer as zInfer } from "zod";

export const PingBodyNameSchema = string()
	.min(3, "Name must be at least 3 characters")
	.optional();

export const PingBodySchema = object({
	name: PingBodyNameSchema,
})
	.strict()
	.optional();

export const PingParamSchema = object({}).strict();

export const PingQuerySchema = object({}).strict();

export const PingResponseSchema = object({
	message: string(),
	timestamp: string(),
	userId: string(),
}).strict();

export type TPingBodySchema = zInfer<typeof PingBodySchema>;
export type TPingParamSchema = zInfer<typeof PingParamSchema>;
export type TPingQuerySchema = zInfer<typeof PingQuerySchema>;
export type TPingResponseSchema = zInfer<typeof PingResponseSchema>;
