import {
	APP_NAME,
	CLIENT_EVENTS_AUTHORISER,
	CLIENT_EVENTS_ENDPOINT,
	STAGE,
} from "@app/constants";
import { useSelector } from "@app/hooks";
import { selectUserId } from "@app/store/features/auth";
import {
	Notifications as UINotifications,
	notifications,
} from "@mantine/notifications";
import { notificationsTopic } from "@pantry-family/topics";
import { iot, mqtt } from "aws-iot-device-sdk-v2";
import { useEffect, useState } from "react";

const createConnection = (
	endpoint: string,
	authoriser: string,
	userId: string,
) => {
	const client = new mqtt.MqttClient();
	const id = window.crypto.randomUUID();

	return client.new_connection(
		iot.AwsIotMqttConnectionConfigBuilder.new_with_websockets()
			.with_clean_session(true)
			.with_client_id(`client_${id}`)
			.with_endpoint(endpoint)
			.with_custom_authorizer(userId, authoriser, "", `USER_ID:${userId}`)
			.build(),
	);
};

export const Notifications = () => {
	const [_, setConnection] = useState<mqtt.MqttClientConnection | null>(null);
	const userId = useSelector(selectUserId);

	useEffect(() => {
		if (!userId) return;

		const connection = createConnection(
			CLIENT_EVENTS_ENDPOINT,
			CLIENT_EVENTS_AUTHORISER,
			userId,
		);

		connection.on("connect", async () => {
			try {
				await connection.subscribe(
					`${APP_NAME}/${STAGE}/${notificationsTopic}/${userId}`,
					mqtt.QoS.AtLeastOnce,
				);

				setConnection(connection);
			} catch (e) {}
		});

		connection.on("message", (_fullTopic, payload) => {
			const message = new TextDecoder("utf8").decode(new Uint8Array(payload));
			notifications.show({
				title: "New notification",
				message,
				autoClose: 3000,
			});
		});

		connection.on("error", console.error);

		connection.on("connection_failure", console.error);

		connection.connect();

		return () => {
			connection.disconnect();

			setConnection(null);
		};
	}, [userId]);

	return <UINotifications />;
};
