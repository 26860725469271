import { useDispatch } from "@app/hooks";
import { signOut } from "@app/store/features/auth";
import {
	Anchor,
	Avatar,
	Burger,
	Flex,
	Group,
	Image,
	Menu,
	NavLink,
	ScrollArea,
	AppShell as UIAppShell,
	rem,
} from "@mantine/core";
import { useDisclosure, useHeadroom } from "@mantine/hooks";
import { IconLogout2 } from "@tabler/icons-react";
import { Link } from "@tanstack/react-router";
import { Logo } from "../logo.component";
import { Notifications } from "../notifications.component";
import classes from "./app-shell.module.css";

export const AppShell = ({ children }: { children: React.ReactNode }) => {
	const dispatch = useDispatch();
	const [navbarOpened, { toggle: toggleNavbar }] = useDisclosure();
	const pinned = useHeadroom({ fixedAt: 120 });

	const handleLogout = () => {
		dispatch(signOut());
	};

	return (
		<UIAppShell
			layout="alt"
			header={{ height: 60, collapsed: !pinned, offset: false }}
			navbar={{
				width: 250,
				breakpoint: "sm",
				collapsed: { mobile: !navbarOpened },
			}}
			padding="md"
		>
			<UIAppShell.Header className={classes.header}>
				<Group h="100%" px="md">
					<Burger
						opened={navbarOpened}
						onClick={toggleNavbar}
						hiddenFrom="sm"
						size="sm"
					/>
				</Group>
				<Group h="100%" px="md">
					<Menu shadow="md" width={200}>
						<Menu.Target>
							<Avatar radius="xl" className={classes.avatar} />
						</Menu.Target>

						<Menu.Dropdown>
							<Menu.Item
								leftSection={
									<IconLogout2 style={{ width: rem(14), height: rem(14) }} />
								}
								onClick={handleLogout}
							>
								Logout
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Group>
			</UIAppShell.Header>
			<UIAppShell.Navbar>
				<UIAppShell.Section p="md">
					<Flex justify="center">
						<Anchor
							component={Link}
							to="/"
							onClick={() => {
								toggleNavbar();
							}}
						>
							<Image w={150} h={150} fit="cover" src={Logo} />
						</Anchor>
					</Flex>
				</UIAppShell.Section>
				<UIAppShell.Section grow component={ScrollArea}>
					<NavLink
						component={Link}
						to="/"
						label="Home"
						onClick={() => {
							toggleNavbar();
						}}
					/>
					<NavLink
						component={Link}
						to="/about"
						label="About"
						onClick={() => {
							toggleNavbar();
						}}
					/>
				</UIAppShell.Section>
				<UIAppShell.Section p="md">Footer</UIAppShell.Section>
			</UIAppShell.Navbar>
			<UIAppShell.Main pt={`calc(${rem(60)} + var(--mantine-spacing-md))`}>
				{children}
			</UIAppShell.Main>
			<Notifications />
		</UIAppShell>
	);
};
