import { App } from "@app/app";
import { StrictMode } from "react";
import { type Container, createRoot } from "react-dom/client";

const container = document.getElementById("root") as Container;
const root = createRoot(container);

root.render(
	<StrictMode>
		<App />
	</StrictMode>,
);
