import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";

import { router } from "@app/router";
import { store } from "@app/store";
import { MantineProvider as UIProvider } from "@mantine/core";
import { RouterProvider } from "@tanstack/react-router";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { FLAGSMITH_CLIENT_KEY } from "./constants";

const persistor = persistStore(store);

export const App = () => {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<FlagsmithProvider
					options={{
						environmentID: FLAGSMITH_CLIENT_KEY,
					}}
					flagsmith={flagsmith}
				>
					<UIProvider defaultColorScheme="auto">
						<RouterProvider router={router} />
					</UIProvider>
				</FlagsmithProvider>
			</PersistGate>
		</Provider>
	);
};
