import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
	persistReducer,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
	persistConfig as authPersistConfig,
	reducer as authReducer,
} from "./features/auth";
import {
	persistConfig as counterPersistConfig,
	reducer as counterReducer,
} from "./features/counter";
import { pingApi } from "./services/ping";

const rootPersistConfig = {
	key: "root",
	storage: storage,
	whitelist: ["counter"],
};

const rootReducer = combineReducers({
	auth: persistReducer(authPersistConfig, authReducer),
	counter: persistReducer(counterPersistConfig, counterReducer),
	[pingApi.reducerPath]: pingApi.reducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const middleware = [pingApi.middleware];

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(...middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
